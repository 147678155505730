import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import './Footer.css'
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    whatsapp: {
        color: '#25D366', // Change the color to your desired color
        fontSize: "30px"
    },
    instagram: {
        color: "#E1306C",
        fontSize: "30px"
    },
    facebook: {
        color: "#1877F2",
        fontSize: "30px"
    },
    telegram: {
        color: "#0088cc",
        fontSize: "30px"
    }
});

const Footer = () => {
    const classes = useStyles();

    return <footer className="footer-container">
        <div className='footer-content'>
            <Link><img src='/static/icons/logo1.png' alt='logo' className='footer-logo' /></Link>
            <ul className='footer-navigation' aria-label="Navigation">
                <li><Link to="/">მთავარი გვერდი</Link></li>
                <li><Link to="/catalogue">კატალოგი</Link></li>
                <li><Link to="/register-as-dealer">დილერად რეგისტრაცია</Link></li>
                {/* <li><Link to="/container-tracking">კონტეინერის ძებნა</Link></li> */}
                <li><Link to="/contact">კონტაქტი</Link></li>
            </ul>
            <ul className='footer-contacts'>
                <li>ელ. ფოსტა: <a href='mailto:info@autoimports.ge'>info@autoimports.ge</a></li>
                <li>ტელ. ნომერი: <a href='tel:+995595951952'>+995 595-951-952</a></li>
            </ul>

        </div>
    </footer>
}

export default Footer
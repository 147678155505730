import Offer from "./Offer";
import styles from "./DealerServices.module.css";
import RegAsDealer from "./RegAsDealer";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';

const DealerServices = () => {
  return (
    <div className={styles["dealer-services-container"]}>
      <h2 className={styles["section-title"]}>
        შეიძინეთ ავტომობილი ამერიკის <br /> უმსხვილესი აუქციონებიდან
      </h2>
      <p className={styles["services-description"]}>
        გახდი ჩვენი დილერი, მიიღეთ შანსი ჩაერთოთ ამერიკის უმსხვილეს აუქციონებში და{" "}
        <br />
        შეიძინო ავტომობილები ამერიკის უმსხვილესი აუქციონებიდან
      </p>
      <div className={styles["services-container"]}>
        <Offer
          offerTitle="მიიღე აუქციონის კოდები"
          offerText="მიიღეთ შესაძლებლობა ჩაერთოდ აუქციონებზე ვაჭრობის პროცესში და გამოიმუშავოთ თანხა ავტო იმპორტით."
          bColor="rgba(33, 42, 49, 0.5);"
          icon={<RegAsDealer iconWidth="50" color="black" />}
        />
        <Offer
          offerTitle="ისარგებლე სპეციალური ტრანსპორტირების ფასები"
          offerText="ჩვენ გთავაზობთ ტრანსპორტირების საუკეთესო ფასებსა და პირობებს."
          bColor="rgba(33, 42, 49, 0.5);"
          icon={<LocalShippingOutlinedIcon style={{width: "50px", height: "50px"}} iconWidth="50" color="black" />}
        />
        <Offer
          offerTitle="დაიმტკიცე დაფინანსება"
          offerText="საჭიროების შემთხვევაში გთავაზობთ ავტომობილის დაფინანსებას 40 პროცენტიანი თანამონაწილეობით."
          bColor="rgba(33, 42, 49, 0.5);"
          icon={<CreditScoreOutlinedIcon style={{width: "50px", height: "50px"}} iconWidth="50" color="black" />}
        />
      </div>
    </div>
  );
};

export default DealerServices;

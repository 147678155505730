import Carousel from "react-bootstrap/Carousel";
import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import "./market.css";
import SendIcon from "@mui/icons-material/Send";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import PersonIcon from "@mui/icons-material/Person";
import { Button } from "@mui/material";
import { Modal } from "react-bootstrap";
import MarketCars from "./MarketCars";
import { useSpringCarousel } from "react-spring-carousel";
import { Helmet } from 'react-helmet';
import ShareIcon from '@mui/icons-material/Share';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const CarDetail = ({role}) => {
  const [images, setImages] = useState([]);
  const [index, setIndex] = useState(0);
  const [data, setData] = useState({});
  const { id } = useParams();
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const [cars, setCars] = useState([]);
  const [showConsultationModal, setShowConsultationModal] = useState(false);
  const [consultationForm, setConsultationForm] = useState({
    name: "",
    phone: "",
  });
  const [loading, setLoading] = useState(true);
  const itemsArray = new Array(8).fill("empty");
  const [notification, setNotification] = useState({
    show: false,
    title: "",
    body: "",
  });
  const windowWidth = window.innerWidth;
  let slideToShow = 3.8;

  if (windowWidth < 500) {
    slideToShow = 1;
  } else if (windowWidth < 630) {
    slideToShow = 1.3;
  } else if (windowWidth < 870) {
    slideToShow = 1.8;
  } else if (windowWidth < 1200) {
    slideToShow = 2.8;
  }

  const copyToClipboard = (text) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };

  function queryStringToObject() {
    const search = window.location.search.substring(1);
    const params = search.split('&');
    const result = {};
  
    params.forEach(param => {
      const keyValue = param.split('=');
      const key = decodeURIComponent(keyValue[0]);
      const value = keyValue.length > 1 ? decodeURIComponent(keyValue[1]) : '';
      if (key) {
        result[key] = value;
      }
    });
  
    return result;
  }

  useEffect(() => {
    const product = queryStringToObject();

    const createMetaTag = (property, content) => {
      const metaTag = document.createElement('meta');
      metaTag.setAttribute('property', property);
      metaTag.setAttribute('content', content);
      document.head.appendChild(metaTag);
    };
    
    createMetaTag("og:image", product.image);
    createMetaTag("og:title", product.name);
    createMetaTag("og:description", "");
    createMetaTag("og:url", window.location.href);
    createMetaTag("og:price:amount", product.price);
    createMetaTag("og:price:currency", "USD");

    axios(`/api/market/${id}`).then((response) => {
      const res = response.data.data;
      setData({ ...res });
      setImages(JSON.parse(res.images));
    });
  }, []);

  useEffect(() => {
    axios(
      `/api/market?keyword=&page=${1}&limit=8&mark=&model=&year_from=&year_to=&price_from=&price_to=&fuel=&transmission=`
    )
      .then((res) => {
        setCars([...res.data.data]);
        setLoading(false);
      })
      .catch(() => {});
  }, []);

  const handlers = {
    consultation(e) {
      e.preventDefault();
      axios
        .post("/api/cars/consultation", {
          name: consultationForm.name,
          phone: consultationForm.phone,
          mark: data.mark,
          model: data.model,
          year: data.year,
          vin: data.vin,
          data: data,
        })
        .then(() => {
          setShowConsultationModal(false);
          setNotification({
            show: true,
            title: "მოთხოვნა წარმატებით დამუშავდა",
            body: "კონსულტაციის მოთხოვნა გაგზავნილია. დაელოდეთ ჩვენი წარმომადგენლის ზარს",
          });
        })
        .catch(() => {
          setNotification({
            show: true,
            title: "შეცდომა",
            body: "Something went wrong",
          });
        });
    },
  };

  const ItemsToRender =
    loading == false
      ? cars.map((e) => {
          return {
            id: e.id,
            renderItem: (
              <MarketCars
                role={"user"}
                data={e}
                editCar={() => {}}
                isSlider={true}
              />
            ),
          };
        })
      : itemsArray.map((i, k) => {
          return {
            id: k,
            renderItem: (
              <div style={{ width: "100%" }}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  style={{
                    width: "100%",
                    height: "220px",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  }}
                />
                <div
                  style={{ padding: "8px" }}
                  className="car-details-container"
                >
                  <Skeleton
                    variant="text"
                    style={{ width: "50%" }}
                    sx={{ fontSize: "17px" }}
                  />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Skeleton
                      variant="text"
                      style={{ width: "61px" }}
                      sx={{ fontSize: "14px" }}
                    />
                    <Skeleton
                      variant="text"
                      style={{ width: "71px", marginLeft: "5px" }}
                      sx={{ fontSize: "14px" }}
                    />
                  </div>
                  <hr
                    className="hr"
                    style={{ margin: "8px 0px", borderColor: "#bcbcbc" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Skeleton
                          variant="text"
                          style={{ width: "25px" }}
                          sx={{ fontSize: "14px" }}
                        />
                        <Skeleton
                          variant="text"
                          style={{ width: "50px", marginLeft: "5px" }}
                          sx={{ fontSize: "14px" }}
                        />
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Skeleton
                          variant="text"
                          style={{ width: "75px" }}
                          sx={{ fontSize: "14px" }}
                        />
                        <Skeleton
                          variant="text"
                          style={{ width: "55px", marginLeft: "5px" }}
                          sx={{ fontSize: "14px" }}
                        />
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Skeleton
                          variant="text"
                          style={{ width: "61px" }}
                          sx={{ fontSize: "16px" }}
                        />
                        <Skeleton
                          variant="text"
                          style={{ width: "71px" }}
                          sx={{ fontSize: "19px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ),
          };
        });

  const { carouselFragment, slideToPrevItem, slideToNextItem } =
    useSpringCarousel({
      itemsPerSlide: slideToShow,
      withLoop: true,
      items: ItemsToRender,
    });

  return (
    <>
      <div className="car-details-wrapper">
        <div className="car-detail-page-content">
          <div className="car-detail-page-divider">
            <Carousel
              fade
              className={"slider-box"}
              activeIndex={index}
              onSelect={handleSelect}
            >
              {images.length > 0 ? (
                images.map((i, k) => {
                  return (
                    <Carousel.Item key={k * 100} interval={5000}>
                      <img
                        src={i}
                        alt={"vehicle"}
                        className={"slider-image-item"}
                      />
                    </Carousel.Item>
                  );
                })
              ) : (
                <Carousel.Item>
                  <Skeleton variant={"rectangular"} animation={"wave"} />
                </Carousel.Item>
              )}
            </Carousel>
            <div className={"slider-items-list"}>
              {images.length > 0 &&
                images.map((i, k) => {
                  return (
                    <div
                      key={k * 1500}
                      className={`slider-items-item ${
                        k === index ? "active-slide" : ""
                      }`}
                      style={{ backgroundImage: `url('${i}')` }}
                      onClick={() => setIndex(k)}
                    ></div>
                  );
                })}
            </div>
          </div>
          <div className="car-detail-page-divider">
            <div className="vehicle-information-container">
              <div className="car-details-header">
                <div className="car-details-header-content">
                  <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                    <h3 style={{width: "fit-content"}}>
                      {data.year} {data.mark} {data.model}
                    </h3>
                    <ShareIcon style={{cursor: "pointer"}} onClick={() => {
                      copyToClipboard(`https://autoimports.ge/cars/auto-market/${data.id}`)
                    }} />
                  </div>
                  <h6>
                    <span>მდებარეობა:</span> {data.location}
                  </h6>
                  <h6>
                    <span>ატვირთვის თარიღი:</span> {data.upload_date}
                  </h6>
                </div>
              </div>
              <div className="car-details-information-box">
                <h5>დეტალები</h5>
                <ul className="car-details-information-content">
                  <li>
                    <span className="car-details-titles">განცხადების ID:</span>
                    <span>{id}</span>
                  </li>
                  <li>
                    <span className="car-details-titles">VIN:</span>
                    <span>{data.vin}</span>
                  </li>
                  <li>
                    <span className="car-details-titles">გარბენი:</span>
                    <span>{data.odometer}</span>
                  </li>
                  <li>
                    <span className="car-details-titles">ძრავი:</span>
                    <span>{data.engine}</span>
                  </li>
                  <li>
                    <span className="car-details-titles">საწვავის ტიპი:</span>
                    <span>{data.fuel_type}</span>
                  </li>
                  <li>
                    <span className="car-details-titles">ტრანსმისია:</span>
                    <span>{data.transmission}</span>
                  </li>
                  <li>
                    <span className="car-details-titles">მწარმოებელი:</span>
                    <span>{data.mark}</span>
                  </li>
                  <li>
                    <span className="car-details-titles">მოდელი:</span>
                    <span>{data.model}</span>
                  </li>
                  <li>
                    <span className="car-details-titles">წელი:</span>
                    <span>{data.year}</span>
                  </li>
                  <li>
                    <span className="car-details-titles">ფერი:</span>
                    <span>{data.color}</span>
                  </li>
                  <li>
                    <span className="car-details-titles">მფლობელი:</span>
                    <span>{data.owner}</span>
                  </li>
                  <li>
                    <span className="car-details-titles">გამყიდველი:</span>
                    <span>
                      {data.seller}{" "}
                      <a
                        href={`tel:${data.seller_phone}`}
                        style={{ marginLeft: "20px" }}
                      >
                        <img
                          src="https://img.freepik.com/premium-vector/green-phone-icon_874723-57.jpg"
                          alt="phone"
                          style={{ height: "30px" }}
                        />
                      </a>
                    </span>
                  </li>
                  {role == "admin" && (
                    <>
                      <li>
                        <span className="car-details-titles">მესაკუთრე:</span>
                        <span>{data.owner_fullname}</span>
                      </li>
                      <li>
                        <span className="car-details-titles">
                          მესაკუთრის ნომერი:
                        </span>
                        <span>
                          <a href={`tel:${data.owner_phone}`}>
                            {data.owner_phone}
                          </a>
                        </span>
                      </li>
                      <li>
                        <span className="car-details-titles">
                          გაყიდვის თარიღი:
                        </span>
                        <span>{data.sale_date}</span>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={"consultation-section"}>
          <div className={"sales-manager-account"}>
            <PersonIcon style={{ fontSize: "50px" }} />
            <p className="consultation-text-hide">მოითხოვე კონსულტაცია</p>
          </div>
          <Button
            variant={"contained"}
            onClick={() => setShowConsultationModal(true)}
          >
            კონსულტაცია
          </Button>
        </div>

        <div className="description-box">
          <h4>აღწერა</h4>
          <p className="car-description-text">{data.description}</p>
        </div>
        <div style={{ overflow: "hidden", marginBottom: "130px" }}>
          <div className="section-header-with-button">
            <h4 className="section-title" style={{ paddingTop: "0px" }}>
              შეარჩიეთ ავტომობილი
            </h4>
            <div>
              <button
                className="slide-switcher-button"
                onClick={slideToPrevItem}
              >
                <KeyboardArrowLeftIcon style={{color: "white"}}/>
              </button>
              <button
                className="slide-switcher-button"
                onClick={slideToNextItem}
              >
                <KeyboardArrowRightIcon style={{color: "white"}}/>
              </button>
            </div>
          </div>
          {carouselFragment}
        </div>
        <Modal
          show={showConsultationModal}
          onHide={() => setShowConsultationModal(false)}
          centered
          size={"md"}
        >
          <Modal.Header>
            <h4>კონსულტაციის მოთხოვნა</h4>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className={"consultation-modal-details"}>
                <img
                  src={images[0]}
                  className={"ask-for-consultation-image"}
                  alt={"vehicle"}
                />
                {/* <div className={"car-details"}>
                                <h5>{data !== null && (isMarket ? data.data.attributes.name : `${data.year} ${data.mark} ${data.model}`)}</h5>
                                <h6>{`${data !== null && (!isMarket ? details[0].attributes.vin_code.slice(0, -6) : data.vin.slice(0, -6))}`}<span
                                    style={{ color: "red" }}>{data !== null && (!isMarket ? details[0].attributes.vin_code.slice(-6) : data.vin.slice(-6))}</span></h6>
                                {!isMarket && <h6>{data !== null && details[0].attributes.primary_damage.replaceAll("_", " ")}</h6>}
                            </div> */}
              </div>
              <form
                className={"car-consultation-form"}
                onSubmit={handlers.consultation}
              >
                <div className={"form-group"}>
                  <label>სახელი</label>
                  <input
                    required
                    onChange={(e) =>
                      setConsultationForm((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                    className={"form-control"}
                    type={"text"}
                  />
                </div>
                <div className={"form-group"}>
                  <label>ნომერი</label>
                  <input
                    className={"form-control"}
                    type={"text"}
                    onChange={(e) =>
                      setConsultationForm((prev) => ({
                        ...prev,
                        phone: e.target.value,
                      }))
                    }
                  />
                </div>
                <Button
                  variant={"contained"}
                  type={"submit"}
                  style={{ width: "100%", marginTop: "15px" }}
                  endIcon={<SendIcon />}
                >
                  მოთხოვნის გაგზავნა
                </Button>
              </form>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={notification.show}
          onHide={() => setNotification((prev) => ({ ...prev, show: false }))}
          size="md"
        >
          <Modal.Header>
            <h4>{notification.title}</h4>
          </Modal.Header>
          <Modal.Body>{notification.body}</Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default CarDetail;

import axios from 'axios'
import './MainPageCatalog.css'
import { Box, Button, Grid, Skeleton } from '@mui/material'
import { useEffect, useState } from 'react'
import Car from '../Car/Car'
import { Link } from "react-router-dom";
import { useSpringCarousel } from 'react-spring-carousel'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const MainPageCatalog = () => {
    const [cars, setCars] = useState([]);
    const itemsArray = new Array(8).fill("empty");
    const [loading, setLoading] = useState(true)
    const windowWidth = window.innerWidth;
    let slideToShow = 3.8;

    if(windowWidth < 500){
        slideToShow = 1
    }else if(windowWidth < 630){
        slideToShow = 1.3
    }else if(windowWidth < 870){
        slideToShow = 1.8
    }else if(windowWidth < 1200){
        slideToShow = 2.8
    }

    useEffect(() => {
        axios.post('/api/cars', { currency: "USD", limit: 8, sort: "-created_at", condition: "GOOD" })
            .then((res) => {
                setCars(res.data.data);
                setLoading(false);
            })
            .catch((err) => {

            })
    }, []);

    const ItemsToRender = !loading ? cars.map((e) => {
        return {
            id: e.id,
            renderItem: <Car data={e} isSlider={true} />
        }
    }) : itemsArray.map((i, k) => {
        return {
            id: k,
            renderItem: <div style={{ width: "100%" }}>
                <Skeleton animation="wave" variant="rectangular" style={{ width: "100%", height: "220px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }} />
                <div style={{ padding: "8px" }} className="car-details-container">
                    <Skeleton variant="text" style={{ width: "50%" }} sx={{ fontSize: '17px' }} />
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Skeleton variant="text" style={{ width: "61px" }} sx={{ fontSize: '14px' }} />
                        <Skeleton variant="text" style={{ width: "71px", marginLeft: "5px" }} sx={{ fontSize: '14px' }} />
                    </div>
                    <hr className="hr" style={{ margin: "8px 0px", borderColor: "#bcbcbc" }} />
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                        <div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Skeleton variant="text" style={{ width: "25px" }} sx={{ fontSize: '14px' }} />
                                <Skeleton variant="text" style={{ width: "50px", marginLeft: "5px" }} sx={{ fontSize: '14px' }} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Skeleton variant="text" style={{ width: "75px" }} sx={{ fontSize: '14px' }} />
                                <Skeleton variant="text" style={{ width: "55px", marginLeft: "5px" }} sx={{ fontSize: '14px' }} />
                            </div>
                        </div>
                        <div>
                            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                <Skeleton variant="text" style={{ width: "61px" }} sx={{ fontSize: '16px' }} />
                                <Skeleton variant="text" style={{ width: "71px" }} sx={{ fontSize: '19px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    });

    const { carouselFragment, slideToPrevItem, slideToNextItem } = useSpringCarousel({
        itemsPerSlide: slideToShow,
        withLoop: true,
        items: ItemsToRender,
    })



    return (
        <div className="main-page-cars-catalogue">
            <div className='section-header-with-button'>
                <h4 className="section-title" style={{ paddingTop: "0px" }}>პოპულარული ავტომობილები</h4>
                <div>
                    <button className='slide-switcher-button' onClick={slideToPrevItem}><KeyboardArrowLeftIcon style={{color: "white"}}/></button>
                    <button className='slide-switcher-button' onClick={slideToNextItem}><KeyboardArrowRightIcon style={{color: "white"}}/></button>
                </div>
            </div>
            <div>
                {carouselFragment}
            </div>
            <Link to={"/catalogue"}><Button style={{marginTop: "40px"}} variant="contained">კატალოგი</Button></Link>
        </div>
    )
}

export default MainPageCatalog
import './Services.css'
import Zoom from 'react-reveal/Zoom';

const Services = () => {
    return (
        <section className="services-container">
            <h4 className="section-title">სერვისები</h4>
            <div className='services-sections-list'>
                <Zoom>
                    <div className='services-item'>
                        <div className='service-image-shadow' style={{background: "rgb(233, 239, 245)"}}><img src='/static/icons/services/consultation.svg' alt='service-images' className='services-icons' /></div>
                        <p className={"services-title"}>კონსულტაცია</p>
                        <p className='service-description-text'>დაგეხმარებით შეარჩიოთ თქვენს მოთხოვნებზე მორგებული საუკეთესო ავტომობილი</p>
                    </div>
                </Zoom>
                <Zoom>
                    <div className='services-item'>
                        <div className='service-image-shadow' style={{background: "rgb(255, 237, 240)"}}><img src='/static/icons/services/purchase.svg' alt='service-images' className='services-icons'/></div>
                        <p className={"services-title"}>შეძენა</p>
                        <p className='service-description-text'>მიიღეთ მონაწილოება აუქციონის პროცესში ან ჩვენ დაგეხმარებით სასურველი ავტომობილის შეძენაში</p>
                    </div>
                </Zoom>
                <Zoom>
                    <div className='services-item'>
                        <div className='service-image-shadow' style={{background: "rgb(250, 241, 232)"}}><img src='/static/icons/services/transportation.svg' alt='service-images' className='services-icons'/></div>
                        <p className={"services-title"}>ტრანსპორტირება</p>
                        <p className='service-description-text'>ჩვენი გუნდი უზრუნველყოფს თქვენი ავტომობილის სწრაფ და უსაფრთხო ტრანსპორტირებას კონკურენტულ ფასად</p>
                    </div>
                </Zoom>
            </div>
        </section>
    )
}

export default Services
import { Button, FormControl, Input, InputAdornment, InputLabel } from '@mui/material'
import '../styles/registerAsDealer/RegisterAsDealer.css'
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { useState } from 'react';
import axios from "axios";
import {Modal} from "react-bootstrap";
import DealerServices from '../Components/dealers/DealerServices';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import NoCrashOutlinedIcon from '@mui/icons-material/NoCrashOutlined';


import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

const DealersRegistration = () => {
    const [formData, setFormData] = useState({ name: "", email: "", phone: "", cars_per_month: "" });
    const [notification, setNotification] = useState({show: false, title: "", body: ""});

    const dealerRegistration = (e) => {
        e.preventDefault();
        axios.post('/api/dealer/registration', formData)
            .then(() => {
                setNotification({show: true, title: "მოთხოვნა წარმატებით გაიგზავნა", body: "კონსულტაციის მოთხოვნა გაგზავნილია. დაელოდეთ ჩვენი წარმომადგენლის ზარს"});
                setFormData({ name: "", email: "", phone: "", cars_per_month: "" });
            })
            .catch(() => {
                setNotification({show: true, title: "შეცდომა", body: "რაღაც წარუმატებელი მოხდა"})
                setFormData({ name: "", email: "", phone: "", cars_per_month: "" });
            })
    }

    return (
        <div className="register-as-dealer-container">
            <div className="register-as-dealer-banner" style={{ backgroundImage: "url('/static/images/dealers-banner.jpg')" }}>
                <h2>გახდი ჩვენი პარტნიორი და გამოიმუშავე თანხა ამერიკის აუქციონებზე მონაწილეობით</h2>
            </div>
            <div className='dealer-registration-form-container'>
                <h4>დარეგისტრირდი დილერად</h4>
                <form className='dealer-registration-form' onSubmit={dealerRegistration}>
                    <div className='form-group' style={{ marginTop: "20px" }}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="input-with-icon-adornment">
                                სახელი
                            </InputLabel>
                            <Input
                                id="name"
                                required
                                value={formData.name}
                                onChange={(e) => !(/^[0-9]+$/.test(e.target.value)) && setFormData((prev) => ({ ...prev, name: e.target.value }))}
                                type='text'
                                name='name'
                                startAdornment={
                                    <InputAdornment position="start">
                                        <PersonOutlinedIcon />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                    <div className='form-group' style={{ marginTop: "20px" }}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="input-with-icon-adornment">
                                ელ. ფოსტა
                            </InputLabel>
                            <Input
                                id="email"
                                required
                                value={formData.email}
                                onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))}
                                type='email'
                                name='email'
                                startAdornment={
                                    <InputAdornment position="start">
                                        <ContactMailOutlinedIcon />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                    <div className='form-group' style={{ marginTop: "20px" }}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="input-with-icon-adornment">
                                ტელ. ნომერი
                            </InputLabel>
                            <Input
                                required
                                type='text'
                                value={formData.phone}
                                onChange={(e) => (/^[0-9]+$/.test(e.target.value) || e.target.value === "") && setFormData((prev) => ({ ...prev, phone: e.target.value }))}
                                name='phone'
                                id="phone"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <LocalPhoneOutlinedIcon />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                    <div className='form-group' style={{ marginTop: "20px" }}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="input-with-icon-adornment">
                                მანქანების რაოდენობა თვეში
                            </InputLabel>
                            <Input
                                required
                                type='number'
                                value={formData.cars_per_mongth}
                                onChange={(e) => setFormData((prev) => ({ ...prev, cars_per_mongth: e.target.value }))}
                                name='cars-per-month'
                                id="cars-per-month"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <NoCrashOutlinedIcon />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                    <Button type='submit' style={{ marginTop: "20px", width: "50%" }} startIcon={<HowToRegIcon />} variant='contained'>მოთხოვნის გაგზავნა</Button>
                </form>
            </div>
            <DealerServices />
            
            <Modal show={notification.show} onHide={() => setNotification((prev) => ({...prev, show: false}))} size="md">
                <Modal.Header><h4>{notification.title}</h4></Modal.Header>
                <Modal.Body>{notification.body}</Modal.Body>
            </Modal>
        </div>
    )
}

export default DealersRegistration
import { useEffect, useState } from "react";
import "./admin.css";
import axios from "axios";
import { Box, Button, Grid } from "@mui/material";
import { Modal } from "react-bootstrap";
import MarketCars from "../../Components/MarketCars/MarketCars";
import DeleteIcon from '@mui/icons-material/Delete';

const Market = ({ user }) => {
  const [cars, setCars] = useState([]);
  const [marks, setMarks] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [filter, setFilter] = useState({
    limit: 20,
    mark: "",
    model: "",
    year_from: "1970",
    year_to: new Date().getFullYear(),
    price_from: "0",
    price_to: "300000",
    fuel: "",
    transmission: "",
  });
  const [loading, setLoading] = useState(false);
  const y = new Array(60).fill('DefaultValue');
  const [allModel, setAllModel] = useState([]);
  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [models, setModels] = useState([]);
  const [formData, setFormData] = useState({
    vin: "",
    upload_date: "",
    price: "",
    images: null,
    profile_image: null,
    year: "",
    mark: "",
    model: "",
    engine: "",
    fuel_type: "",
    color: "",
    odometer: "",
    transmission: "",
    location: "",
    sold: false,
    seller: "",
    seller_phone: "",
    owner: "",
    description: "",
    owner_fullname: "", owner_phone: "", manager: ""
  });
  const [active, setActive] = useState({});
  const [selectedImages, setSelectedImages] = useState([]);
  const [profileImage, setProfileImage] = useState(null);
  const [sellers, setSellers] = useState([]);
  const [sellerForm, setSellerForm] = useState({fullname: "", seller_phone: ""});
  const [showSellerForm, setShowSellerForm] = useState(false);
  const [action, setAction] = useState("addnew");
  const [images, setImages] = useState({car: [],profile: ""});
  const [profileImageChange, setProfileImageChange] = useState("no");
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);

  function getSellers(){
    axios('/api/sellers')
      .then((res) => {
        const data = res.data.data;
        setSellers(data)
      })
      .catch(() => {
  
      })
  }

  useEffect(() => {
    axios(
      `/api/market?keyword=${keyword}&page=${page}&limit=${filter.limit}&mark=${filter.mark}&model=${filter.model}&year_from=${filter.year_from}&year_to=${filter.year_to}&price_from=${filter.price_from}&price_to=${filter.price_to}&fuel=${filter.fuel}&transmission=${filter.transmission}`
    )
      .then((res) => {
        const data = res.data.data;
        if(keyword == ""){
          setCars((prev) => ([...prev, ...data]));
        }else{
          setCars([...data])
        }
      })
      .catch(() => {});

    getSellers();
  }, [page, keyword]);

  useEffect(() => {
    axios("/api/cars/models")
      .then((res) => {
        const data = res.data.data;
        setAllModel(data);
        const uniqueMarks = new Set();
        const filteredArray = data.filter((item) => {
          if (!uniqueMarks.has(item.mark)) {
            uniqueMarks.add(item.mark);
            return true;
          }
          return false;
        });
        let mark = filteredArray.map((a) => a.mark);
        mark = mark.sort((a, b) => a.localeCompare(b));
        setMarks([...mark]);
      })
      .catch((err) => {});
  }, []);

  const processImage = (image) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const logo = new Image();
    logo.src = '/static/icons/logoo.png'; // Path to your logo image
    logo.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;

      ctx.drawImage(image, 0, 0);
      const logoWidth = 100; // Adjust logo width
      const logoHeight = 100; // Adjust logo height
      ctx.drawImage(logo, canvas.width - logoWidth - 10, 10, logoWidth, logoHeight);

      const dataUrl = canvas.toDataURL('image/png');
      setProfileImage(dataUrl);
    };
  };

  const loadLogo = (src) => {
    return new Promise((resolve, reject) => {
      const logo = new Image();
      logo.src = src;
      logo.onload = () => resolve(logo);
      logo.onerror = reject;
    });
  };

  const addLogoToImage = (image, logo) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = image.width;
    canvas.height = image.height;

    ctx.drawImage(image, 0, 0);
    const logoWidth = 100; // Adjust logo width
    const logoHeight = 100; // Adjust logo height
    ctx.drawImage(logo, canvas.width - logoWidth - 10, 10, logoWidth, logoHeight);

    return canvas.toDataURL('image/png');
  };

  const processImages = async (files) => {
    const logo = await loadLogo('/static/icons/logoo.png'); // Load the logo image once

    const processedImagePromises = files.map(file => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const image = new Image();
          image.onload = () => {
            const processedDataUrl = addLogoToImage(image, logo);
            resolve(processedDataUrl);
          };
          image.src = e.target.result;
        };
        reader.readAsDataURL(file);
      });
    });

    const processedDataUrls = await Promise.all(processedImagePromises);
    setSelectedImages(processedDataUrls);
  };

  const processImagess = (image) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const logo = new Image();
    logo.src = '/static/icons/logoo.png'; // Path to your logo image
    logo.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;

      ctx.drawImage(image, 0, 0);
      const logoWidth = 100; // Adjust logo width
      const logoHeight = 100; // Adjust logo height
      ctx.drawImage(logo, canvas.width - logoWidth - 10, 10, logoWidth, logoHeight);

      const dataUrl = canvas.toDataURL('image/png');
      setProfileImage(dataUrl);
    };
  };

  const handleImageChange = (event) => {
    setSelectedImages([]);
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    if (files.length > 0) {
      processImages(files);
    }
  };

  const handleProfileImageChange = (event) => {
    setProfileImage(null)
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        image.onload = () => {
          processImage(image);
        };
        image.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  };

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    if(formData.mark == "") alert("Please select mark");
    if(formData.model == "") alert("Please select model");
    if(formData.year == "") alert("Please select year");
    if(formData.fuel_type == "") alert("Please select fuel type");
    if(formData.seller == "") alert("Please select seller");
    if(formData.mark != "" && formData.model != "" && formData.year != "" && formData.fuel_type != "" && formData.seller != ""){
      const form = new FormData();
      if(profileImage != null){
        const imageBlob = dataURLtoBlob(profileImage);
        form.append("images", imageBlob);
      }
      if(selectedImages.length > 0){
        selectedImages.forEach((imageDataUrl, index) => {
          const blob = dataURLtoBlob(imageDataUrl);
          form.append(`images`, blob);
        });
      }
  
      form.append("vin", formData.vin);
      form.append("upload_date", new Date());
      form.append("manager", formData.manager);
      form.append("owner_phone", formData.owner_phone);
      form.append("owner_fullname", formData.owner_fullname);
      form.append("price", formData.price);
      form.append("year", formData.year);
      form.append("mark", formData.mark);
      form.append("model", formData.model);
      form.append("engine", formData.engine);
      form.append("fuel_type", formData.fuel_type);
      form.append("color", formData.color);
      form.append("odometer", formData.odometer);
      form.append("transmission", formData.transmission);
      form.append("location", formData.location);
      form.append("sold", formData.sold);
      form.append("seller_phone", formData.seller_phone);
      form.append("seller", formData.seller);
      form.append("owner", formData.owner);
      form.append("description", formData.description);
      form.append("profileImageChange", profileImageChange);
  
      const url = action == "addnew" ? '/api/market' : `/api/market/${active.id}`;
      const methodType = action == "addnew" ? 'post' : `put`;
      axios[methodType](url, form)
        .then((res) => {
          setShowAddNewModal(false);
          setLoading(false);
          alert(res.data.message);
          setProfileImageChange("no");
        })
        .catch((err) => {
          setLoading(false);
          alert(err.response.data.message);
        })
    }
  };

  const addNewSeller = () => {
    axios.post('/api/sellers', sellerForm)
      .then(() => {
        getSellers();
        setShowSellerForm(false);
      })
      .catch(() => {

      })
  }

  const editCar = (id) => {
    setAction("editcar");
    axios(`/api/market/${id}`)
      .then((response) => {
          const res = response.data.data;
          setActive({...res});
          setFormData({
            vin: res.vin,
            upload_date: res.upload_date,
            price: res.price,
            images: res.images,
            profile_image: res.profile_image,
            year: res.year,
            mark: res.mark,
            model: res.model,
            engine: res.engine,
            fuel_type: res.fuel_type,
            color: res.color,
            odometer: res.odometer,
            transmission: res.transmission,
            location: res.location,
            sold: res.sold,
            seller: res.seller,
            seller_phone: res.seller_phone,
            owner: res.owner,
            description: res.description,
            owner_fullname: res.owner_fullname, owner_phone: res.owner_phone, manager: res.manager
          });
          const img = JSON.parse(res.images);
          setImages({car: [...img], profile: res.profile_image})
          setShowAddNewModal(true);
      })
  }

  const removeimage = (carId, imageId) => {
    axios.delete(`/api/market/${carId}/images/${imageId}`)
      .then((res) => {
        setImages((prev) => {
          const renew = prev.car.splice(imageId, 1);
          return {...prev, cas: renew}
        })
      })
      .catch((err) => {
        
      })
  }

  return (
    <div className="market-page-container">
      {user.role === "admin" && (
        <div className="market-page-header">
          <button
            className="add-new-button"
            onClick={() => {
              setShowAddNewModal(true)
              setFormData({
                vin: "",
                upload_date: "",
                price: "",
                images: null,
                profile_image: null,
                year: "",
                mark: "",
                model: "",
                engine: "",
                fuel_type: "",
                color: "",
                odometer: "",
                transmission: "",
                location: "",
                sold: false,
                seller: "",
                seller_phone: "",
                owner: "",
                description: ""
              })
            }}
          >
            Add New
          </button>
          <button onClick={() => setShowSellerForm(true)} className="add-new-seller">
            Add New Seller
          </button>
        </div>
      )}
      <div className="catalogue-page-header" style={{marginTop: "20px"}}>
        <h4>Catalogue</h4>
        <div style={{display: "flex", alignItems: "center"}}>
          <input 
            className="form-control"
            style={{marginRight: "10px"}}
            placeholder="საძიებო სიტყვა"
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button type="button" variant="contained" onClick={() => {
            setPage(1);
            setKeyword(search)
          }}>ძებნა</Button>
        </div>
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {cars.map((c) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} key={c.id}>
                <MarketCars role={user.role} data={c} editCar={editCar} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "35px",
          marginBottom: "40px",
        }}
      >
        <Button variant="contained" onClick={() => setPage((prev) => prev + 1)} style={{ minWidth: "250px" }}>
          Load More
        </Button>
      </div>
      <Modal show={showSellerForm} onHide={() => setShowSellerForm(false)}>
        <Modal.Header><h5>Add new seller</h5></Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Fullname</label>
            <input className="form-control" placeholder="Enter fullname" onChange={(e) => setSellerForm((prev) => ({...prev, fullname: e.target.value}))}/>
          </div>
          <div className="form-group">
            <label>Phone</label>
            <input className="form-control" placeholder="Enter phone" onChange={(e) => setSellerForm((prev) => ({...prev, seller_phone: e.target.value}))}/>
          </div>
          <Button onClick={addNewSeller} style={{width: "100%"}} variant="contained">Add New</Button>
        </Modal.Body>
      </Modal>
      <Modal
        show={showAddNewModal}
        onHide={() => setShowAddNewModal(false)}
        centered
        size={"lg"}
      >
        <Modal.Header>
          <h3>დამატება</h3>
        </Modal.Header>
        <Modal.Body>
          <form className="add-new-car-modal" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="input-label">Mark</label>
                  <select
                    className="form-control"
                    value={formData.mark}
                    onChange={(e) => {
                      let filterModels = allModel.filter(
                        (m) => m.mark === e.target.value
                      );
                      filterModels = filterModels.sort()
                      setModels(filterModels.reverse());
                      setFormData((prev) => ({
                        ...prev,
                        mark: e.target.value,
                      }));
                    }}
                  >
                    <option>Select Mark</option>
                    {marks.map((m) => (
                      <option value={m}>{m}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label className="input-label">Model</label>
                  <select
                    className="form-control"
                    value={formData.model}
                    onChange={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        model: e.target.value,
                      }));
                    }}
                  >
                    <option>Select Model</option>
                    {models.map((m) => (
                      <option value={m.model.replace(`${m.mark} `, "")}>
                        {m.model.replace(`${m.mark} `, "")}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label className="input-label">Year</label>
                  <select className="form-control" onChange={(e) => setFormData((prev) => ({ ...prev, year: e.target.value }))}>
                    <option>Select year</option>
                    {
                      y.map((v, i) => {
                        let date = new Date();
                        const year = date.getFullYear() - i;
                        return <option key={year} value={year}>{year}</option>
                      })
                    }
                  </select>
                </div>
                <div className="form-group">
                  <label className="input-label">VIN</label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    value={formData.vin}
                    placeholder="Enter vin"
                    onChange={(e) =>
                      setFormData((prev) => ({ ...prev, vin: e.target.value }))
                    }
                  />
                </div>
                <div className="form-group">
                  <label className="input-label">Price</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={formData.price}
                    placeholder="Enter price"
                    onChange={(e) =>{                      
                      if(/^[0-9]*$/.test(e.target.value) || e.target.value === ''){
                        setFormData((prev) => ({
                          ...prev,
                          price: e.target.value,
                        }))
                      }}
                    }
                  />
                </div>
                <div className="form-group">
                  <label className="input-label">Engine</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={formData.engine}
                    placeholder="Enter engine"
                    onChange={(e) =>{
                      if(/^[0-9.]*$/.test(e.target.value) || e.target.value === ''){
                        setFormData((prev) => ({
                          ...prev,
                          engine: e.target.value,
                        }))
                      }}}
                  />
                </div>
                <div className="form-group">
                  <label className="input-label">Fuel type</label>
             
                  <select className="form-control" onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      fuel_type: e.target.value,
                    }))
                  }}>
                    <option>Select fuel type</option>
                    <option key={"ბენზინი"} >ბენზინი</option>
                    <option key={"დიზელი"} >დიზელი</option>
                    <option key={"ელექტრო"} >ელექტრო</option>
                    <option key={"ჰიბრიდი"} >ჰიბრიდი</option>
                    <option key={"დატენვადი ჰიბრიდი"} >დატენვადი ჰიბრიდი</option>
                    <option key={"თხევადი გაზი"} >თხევადი გაზი</option>
                    <option key={"ბუნებრივი აირი"} >ბუნებრივი აირი</option>
                  </select>
                </div>
                <div className="form-group">
                  <label className="input-label">Color</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formData.color}
                    placeholder="Enter color"
                    required
                    onChange={(e) =>{
                      if(/^[a-zA-Zა-ჰ]*$/.test(e.target.value) || e.target.value === ''){
                        setFormData((prev) => ({
                          ...prev,
                          color: e.target.value,
                        }))
                      }
                    }}
                  />
                </div>
                <div className="form-group">
                  <label className="input-label">Odometer</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formData.odometer}
                    placeholder="Enter odometer"
                    required
                    onChange={(e) =>{
                      if(/^[0-9]*$/.test(e.target.value) || e.target.value === ''){
                        setFormData((prev) => ({
                          ...prev,
                          odometer: e.target.value,
                        }))
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="input-label">Transmission</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formData.transmission}
                    placeholder="Enter transmission"
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        transmission: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="form-group">
                  <label className="input-label">Location</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formData.location}
                    placeholder="Enter location"
                    required
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        location: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="form-group">
                  <label className="input-label">Owner</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formData.owner}
                    placeholder="Enter owner name"
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        owner: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="form-group">
                  <label className="input-label">Seller</label>
                  <select 
                    className="form-control" 
                    value={formData.seller} 
                    onChange={(e) => setFormData((prev) => {
                      const selectedSeller = sellers.filter((s) => s.fullname == e.target.value);
                      
                      return {...prev, seller: e.target.value, seller_phone: selectedSeller[0].phone}
                    })}
                  >
                    <option value={""}>Select Seller</option>
                    {
                      sellers.map((s) => <option value={s.fullname}>{s.fullname}</option>)
                    }
                  </select>
                </div>
                <div className="form-group">
                  <label className="input-label">Owner fullname</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formData.owner_fullname}
                    placeholder="Enter car owner fullname"
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        owner_fullname: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="form-group">
                  <label className="input-label">Owner phone</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formData.owner_phone}
                    placeholder="Enter car owner phone"
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        owner_phone: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="form-group">
                  <label className="input-label">Manager Info</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formData.manager}
                    placeholder="Enter Manager Info"
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        manager: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="form-group">
                  <label className="input-label" style={{width: "100%"}}>Sold</label>
                  <input
                    type="checkbox"
                    defaultChecked={formData.sold}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        sold: e.target.checked,
                      }))
                    }
                  />
                </div>
              </div>

              <div className="form-group">
                  <label className="form-label">Description</label>
                  <textarea 
                    style={{height: "110px"}} 
                    className="form-control" 
                    placeholder="Description text" 
                    onChange={(e) => setFormData((prev) => {
                      return {...prev, description: e.target.value}
                    })} 
                    value={formData.description} 
                  />
              </div>

              <div className="form-group">
                <label className="">Images</label>
                <input type="file" className="form-control" onChange={handleImageChange} multiple accept="image/*" />
                <div className="mt-4">
                  {selectedImages.map((image, index) => (
                    <img key={index} src={image} alt={`Selected Image ${index}`} style={{ width: '150px', marginRight: '10px', marginBottom: "10px" }} />
                  ))}
                  {
                    action == "editcar" && (
                      <div className="car-images-wrapper">
                        {
                          images.car.map((i, index) => {
                            return <div style={{position: "relative"}}> <span onClick={() => removeimage(active.id, index)}><DeleteIcon/></span> <img key={index} src={i} alt={`Selected Image ${index}`} style={{ width: '150px', marginRight: '10px', marginBottom: "10px" }} /></div>
                          })
                        }
                      </div>
                    )
                  }
                </div>
              </div>
              <div>
                <label className="form-label">Profile Image</label>
                <input type="file" className="form-control" accept="image/*" onChange={handleProfileImageChange} />
                <div className="mt-4">
                  {profileImage != null && <img src={profileImage} alt="Selected Image" style={{ maxWidth: '300px', maxHeight: '300px' }} />}
                </div>
              </div>

              <div className="form-group">
                <button disabled={loading} title={loading ? "Loading" : ""} className={`add-new-record-button ${loading ? "loading-button" : ""}`}>{action == "addnew" ? "Add New" : "Save"}</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Market;

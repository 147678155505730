import { Box, Button, Grid, Skeleton } from "@mui/material";
import AsideSearchModal from "../Components/AsideSearchModal/AsideSearchModal"
import Car from "../Components/Car/Car";
import { useEffect, useState } from "react";
import axios from "axios";

const Catalogue = () => {
    const [cars, setCars] = useState([]);
    const [formData, setFormData] = useState({ mark: "", model: "", yearsFrom: "", yearsTo: "", priceFrom: "", priceTo: "", condition: "", auction: "", sort: "-created_at", fuel: "", millageFrom: "", millageTo: "", transmission: "", drive: "" })
    const [loading, setLoading] = useState(true);
    const itemsArray = new Array(8).fill("empty");
    const [next, setNext] = useState("");

    useEffect(() => {
        const urlVars = new URLSearchParams(window.location.search);
        const mark = urlVars.get("mark") === null ? '' : urlVars.get("mark").replaceAll(" ", "_");
        const model = urlVars.get("model") === null ? '' : urlVars.get("model").replaceAll(" ", "_");
        const yearFrom = urlVars.get("yearFrom") === null ? '' : urlVars.get("yearFrom").replaceAll(" ", "_");
        const yearTo = urlVars.get("yearTo") === null ? '' : urlVars.get("yearTo").replaceAll(" ", "_");
        window.scrollTo(0, 0);
        axios.post(`/api/cars`, {currency: "USD", limit:24, sort: "-created_at", mark: mark, model: model, yearFrom: yearFrom, yearTo: yearTo})
            .then((res) => {
                setCars(res.data.data);
                setLoading(false);
                setNext(res.data.next)
            })
            .catch((err) => {

            })
    }, []);

    const submitSearch = () => {
        setLoading(true);
        const data = {
            currency: "USD",
            limit:24,
            sort: formData.sort,
            condition: formData.condition,
            mark: formData.mark,
            model: formData.model,
            yearFrom: formData.yearsFrom,
            yearTo: formData.yearsTo,
            priceFrom: formData.priceFrom,
            priceTo: formData.priceTo,
            auction: formData.auction,
            fuel: formData.fuel,
            millageFrom: formData.millageFrom,
            millageTo: formData.millageTo,
            transmission: formData.transmission,
            drive: formData.drive,
        }
        axios.post(`/api/cars`, data)
            .then((res) => {
                setCars(res.data.data);
                setLoading(false);
                setNext(res.data.next)
            })
            .catch((err) => {

            })
    }

    const loadMore = () => {
        setLoading(true)
        axios.post(`/api/cars`, {next: next})
            .then((res) => {
                setCars((prev) => ([...prev, ...res.data.data]));
                setLoading(false);
                setNext(res.data.next)
            })
            .catch((err) => {

            })
    }

    return (
        <>
            <AsideSearchModal formData={formData} setFormData={setFormData} submitSearch={submitSearch} />
            <div className={'catalogue-page-container'}>
                <div className="catalogue-page-header">
                    <h4>Catalogue</h4>
                </div>
                {!loading ? (<>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            {
                                cars.map((c) => {
                                    return (
                                        <Grid item xs={12} sm={6} md={4} lg={3} key={c.id}>
                                            <Car data={c} />
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "35px", marginBottom: "40px"}}>
                        <Button variant="contained" onClick={loadMore} style={{minWidth: "250px"}}>მაჩვენე მეტი</Button>
                    </div>
                </>) : (
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            {
                                itemsArray.map((c, k) => {
                                    return (
                                        <Grid item xs={12} sm={6} md={4} lg={3} key={`${c}${k}`}>
                                            <Skeleton animation="wave" variant="rectangular" style={{ width: "100%", height: "220px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }} />
                                            <div style={{ padding: "8px" }} className="car-details-container">
                                                <Skeleton variant="text" style={{ width: "50%" }} sx={{ fontSize: '17px' }} />
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Skeleton variant="text" style={{ width: "61px" }} sx={{ fontSize: '14px' }} />
                                                    <Skeleton variant="text" style={{ width: "71px", marginLeft: "5px" }} sx={{ fontSize: '14px' }} />
                                                </div>
                                                <hr className="hr" style={{ margin: "8px 0px", borderColor: "#bcbcbc" }} />
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                                                    <div>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <Skeleton variant="text" style={{ width: "25px" }} sx={{ fontSize: '14px' }} />
                                                            <Skeleton variant="text" style={{ width: "50px", marginLeft: "5px" }} sx={{ fontSize: '14px' }} />
                                                        </div>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <Skeleton variant="text" style={{ width: "75px" }} sx={{ fontSize: '14px' }} />
                                                            <Skeleton variant="text" style={{ width: "55px", marginLeft: "5px" }} sx={{ fontSize: '14px' }} />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                                            <Skeleton variant="text" style={{ width: "61px" }} sx={{ fontSize: '16px' }} />
                                                            <Skeleton variant="text" style={{ width: "71px" }} sx={{ fontSize: '19px' }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                )}
            </div>
        </>
    )
}

export default Catalogue
import { useEffect, useState } from "react"
import './AsideSearchModal.css'
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from '@mui/styles';
import axios from "axios";
import { Button, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";

const useStyles = makeStyles({
    myIcon: {
        color: 'white', // Change the color to your desired color
    },
});

const AsideSearchModal = ({formData, setFormData, submitSearch}) => {
    const [show, setShow] = useState(false);
    const classes = useStyles();
    const [marks, setMarks] = useState([]);
    const [models, setModels] = useState([]);
    const [showSections, setShowSections] = useState({
        marks: false, models: false, years: false, price: false, millage: false, condition: false, fuel: false, transmission: false, drive: false
    });
    const [marksList, setMarksList] = useState([]);
    const [years, setYears] = useState({
        from: getYearsArray().reverse(),
        to: getYearsArray().reverse()
    });

    const [data, setData] = useState({
        priceFrom: "", priceTo: "", millageFrom: "", millageTo: ""
    })

    const list = [...getYearsArray().reverse()];

    useEffect(() => {
        axios('/api/cars/models')
            .then((res) => {
                const data = res.data.data;
                setMarksList([...data]);
                const uniqueMarks = new Set();
                const filteredArray = data.filter(item => {
                    if (!uniqueMarks.has(item.mark)) {
                        uniqueMarks.add(item.mark);
                        return true;
                    }
                    return false;
                });
                let mark = filteredArray.map((a) => a.mark);
                mark = mark.sort((a, b) => a.localeCompare(b));
                setMarks([...mark]);
            })
            .catch((err) => {

            })
    }, []);

    const handlers = {
        marksChange(newValue) {
            const selectModels = marksList.filter((m) => m.mark === newValue);
            const model = selectModels.map((a) => a.model);
            setModels([...model]);
            setShowSections((prev) => ({ ...prev, marks: false, models: true }))
            setFormData((prev) => ({ ...prev, mark: newValue.replaceAll(" ", "_") }));
        },
        modelsChange(newValue) {
            setFormData((prev) => ({ ...prev, model: newValue.replaceAll(" ", "_") }));
            setShowSections((prev) => ({ ...prev, models: false }))
        },
        condition(e) {
            setFormData((prev) => ({...prev, condition: e.target.value.replaceAll(" ", "_")}))
        },
        auction(e){
            setFormData((prev) => ({...prev, auction: e.target.value}))
        },
        search(){

        }
    }

    return (
        <div className={`search-vehicle-container`}>
            <div className="search-filter-content">
                <div className="catalogue-filter-dropdown-box">
                    <div className="filter-sections-header" style={{ margin: "0" }} onClick={() => setShowSections((prev) => ({ ...prev, marks: !prev.marks }))}>
                        <h5 className="search-filter-section-title">{formData.mark !== "" ? formData.mark : "მწარმოებელი"}</h5>
                        <KeyboardArrowDownIcon style={{color: "black"}} />
                    </div>
                    {(showSections.marks) && <div className={`catalogue-marks-selector ${showSections.marks ? 'show-this-section' : 'hide-this-section'}`} >
                        <ul className="catalogue-marks-list">
                            {
                                showSections.marks && (marks.map((mark, k) => {
                                    return <li onClick={() => handlers.marksChange(mark)} key={k * 5}>{mark}</li>
                                }))
                            }
                        </ul>
                    </div>}
                </div>
                <div className="catalogue-filter-dropdown-box">
                    <div className="filter-sections-header" onClick={() => setShowSections((prev) => ({ ...prev, models: !prev.models }))}>
                        <h5 className="search-filter-section-title">{formData.model == "" ? "მოდელი" : formData.model.replaceAll("_", " ")}</h5>
                        <KeyboardArrowDownIcon style={{color: "black"}} />
                    </div>
                    {(models.length > 0 && showSections.models) && <div className={`catalogue-marks-selector ${(showSections.models && models.length > 0) ? 'show-this-section' : 'hide-this-section'}`} >
                        <ul className="catalogue-marks-list">
                            {(formData.model !== "" && showSections.models === false) ? (
                                <li>{formData.model}</li>
                            ) : (
                                models.map((model, k) => {
                                    return <li onClick={() => handlers.modelsChange(model)} key={k * 5}>{model}</li>
                                })
                            )}
                        </ul>
                    </div>}
                </div>
                <div className="catalogue-filter-dropdown-box">
                    <div className="filter-sections-header" onClick={() => setShowSections((prev) => ({ ...prev, years: !prev.years }))}>
                        {
                            (formData.yearsFrom == "" && formData.yearsTo == "") ? <h5 className="search-filter-section-title">წელი</h5> :
                            <div className="selected-years-block">
                                {
                                    formData.yearsFrom !== "" ? <p>{formData.yearsFrom}</p> : <p>დან</p>
                                }
                                <span>-</span>
                                {
                                    formData.yearsTo !== "" ? <p>{formData.yearsTo}</p> : <p>მდე</p>
                                }
                            </div>
                        }
                        <KeyboardArrowDownIcon style={{color: "black"}} />
                    </div>
                    {showSections.years && <div className={`catalogue-years-selector ${showSections.years ? 'show-this-section' : 'hide-this-section'}`} >
                        <ul className="catalogue-year-list">
                            {
                                years.from.map((y) => {
                                    return <li className={formData.yearsTo === y ? 'selected-year' : ''} onClick={() => {
                                        setYears((prev) => {
                                            const f = list.filter((year) => year >= y)
                                            return { ...prev, to: f }
                                        });
                                        setFormData((prev) => ({ ...prev, yearsFrom: y }));
                                    }} key={y}>{y}</li>
                                })
                            }
                        </ul>
                        <ul className="catalogue-year-list">
                            {
                                years.to.map((y) => {
                                    return <li className={formData.yearsTo === y ? 'selected-year' : ''} onClick={() => {
                                        setYears((prev) => {
                                            const f = list.filter((year) => year <= y)
                                            return { ...prev, from: f }
                                        });
                                        setFormData((prev) => ({ ...prev, yearsTo: y }));
                                    }} key={y}>{y}</li>
                                })
                            }
                        </ul>
                    </div>}
                </div>
                <div className="catalogue-filter-dropdown-box">
                    <div className="filter-sections-header" onClick={() => {
                        setShowSections((prev) => ({...prev, price: !prev.price}));
                    }}>
                        {
                            (formData.priceFrom == "" && formData.priceTo == "") ? <h5 className="search-filter-section-title">ფასი</h5> :
                            <div className="selected-years-block">
                                {
                                    formData.priceFrom !== "" ? <p>{formData.priceFrom}</p> : <p>დან</p>
                                }
                                <span>-</span>
                                {
                                    formData.priceTo !== "" ? <p>{formData.priceTo}</p> : <p>მდე</p>
                                }
                            </div>
                        }
                        <KeyboardArrowDownIcon style={{color: "black"}} />
                    </div>
                    {showSections.price && <div className="filter-section-prices">
                        <TextField
                            label="დან"
                            variant="standard"
                            style={{ width: "48%" }}
                            onChange={(e) => setData((prev) => ({ ...prev, priceFrom: e.target.value }))}
                            value={data.priceFrom}
                        />
                        <TextField
                            label="მდე"
                            variant="standard"
                            style={{ width: "48%" }}
                            onChange={(e) => setData((prev) => ({ ...prev, priceTo: e.target.value }))}
                            value={data.priceTo}
                        />
                        <Button onClick={() => {
                            if(data.priceTo != "" && data.priceFrom > data.priceTo){
                                alert("საწყისი ფასი ნაკლებია საბოლოო ფასზე");
                            }else{
                                setFormData((prev) => ({...prev, priceFrom: data.priceFrom, priceTo: data.priceTo}));
                                setShowSections((prev) => ({...prev, price: false}));
                            }
                        }} variant="contained" style={{width: "100%", marginTop: "15px"}} >შენახვა</Button>
                    </div>}
                </div>
                <div className="catalogue-filter-dropdown-box">
                    <div className="filter-sections-header" onClick={() => setShowSections((prev) => ({...prev, millage: !prev.millage}))}>
                        {
                            (formData.millageFrom == "" && formData.millageTo == "") ? <h5 className="search-filter-section-title">გარბენი</h5> :
                            <div className="selected-years-block">
                                {
                                    formData.priceFrom !== "" ? <p>{formData.millageFrom}</p> : <p>დან</p>
                                }
                                <span>-</span>
                                {
                                    formData.priceTo !== "" ? <p>{formData.millageTo}</p> : <p>მდე</p>
                                }
                            </div>
                        }
                        <KeyboardArrowDownIcon style={{color: "black"}} />
                    </div>
                    {showSections.millage && <div className="filter-section-prices">
                        <TextField
                            label="დან"
                            variant="standard"
                            style={{ width: "48%" }}
                            onChange={(e) => setData((prev) => ({ ...prev, millageFrom: e.target.value }))}
                            value={data.millageFrom}
                        />
                        <TextField
                            label="მდე"
                            variant="standard"
                            style={{ width: "48%" }}
                            onChange={(e) => setData((prev) => ({ ...prev, millageTo: e.target.value }))}
                            value={data.millageTo}
                        />
                        <Button variant="contained" style={{width: "100%", marginTop: "15px"}} onClick={() => {
                            if(data.priceTo != "" && data.millageFrom > data.millageTo){
                                alert("საწყისი გარბენი ნაკლებია საბოლოო გარბენზე");
                            }else{
                                setFormData((prev) => ({...prev, millageFrom: data.millageFrom, millageTo: data.millageTo}));
                                setShowSections((prev) => ({...prev, millage: false}));
                            }
                        }} >შენახვა</Button>
                    </div>}
                </div>
                <div className="catalogue-filter-dropdown-box">
                    <div className="filter-sections-header" onClick={() => setShowSections((prev) => ({...prev, condition: !prev.condition}))}>
                        <h5 className="search-filter-section-title">{formData.condition == "" ? "მდგომარეობა" : formData.condition.replaceAll("_", " ")}</h5>
                        <KeyboardArrowDownIcon style={{color: "black"}} />
                    </div>
                    {(showSections.condition) && <div className={`catalogue-marks-selector`} >
                        <ul className="catalogue-marks-list">
                            <li onClick={() => {
                                setFormData((prev) => ({...prev, condition: "GOOD"}));
                                setShowSections((prev) => ({...prev, condition: false}));
                            }}>დაუზიანებელი</li>
                            <li onClick={() => {
                                setFormData((prev) => ({...prev, condition: "MINOR_DAMAGE"}));
                                setShowSections((prev) => ({...prev, condition: false}));
                            }}>მცირე დაზიანება</li>
                            <li onClick={() => {
                                setFormData((prev) => ({...prev, condition: "DAMAGE"}));
                                setShowSections((prev) => ({...prev, condition: false}));
                            }}>დაზიანებული</li>
                            <li onClick={() => {
                                setFormData((prev) => ({...prev, condition: "OTHER"}));
                                setShowSections((prev) => ({...prev, condition: false}));
                            }}>სხვა</li>
                        </ul>
                    </div>}
                </div>
                <div className="catalogue-filter-dropdown-box">
                    <div className="filter-sections-header" onClick={() => setShowSections((prev) => ({...prev, fuel: !prev.fuel}))}>
                        <h5 className="search-filter-section-title">{formData.fuel == "" ? "საწვავის ტიპი" : formData.fuel.replaceAll("_", " ")}</h5>
                        <KeyboardArrowDownIcon style={{color: "black"}} />
                    </div>
                    {(showSections.fuel) && <div className={`catalogue-marks-selector`}>
                        <ul className="catalogue-marks-list">
                            <li onClick={() => {
                                setFormData((prev) => ({...prev, fuel: "DIESEL"}));
                                setShowSections((prev) => ({...prev, fuel: false}))
                            }}>დიზელი</li>
                            <li onClick={() => {
                                setFormData((prev) => ({...prev, fuel: "ELECTRIC"}));
                                setShowSections((prev) => ({...prev, fuel: false}))
                            }}>ელექტრო</li>
                            <li onClick={() => {
                                setFormData((prev) => ({...prev, fuel: "GAS"}));
                                setShowSections((prev) => ({...prev, fuel: false}))
                            }}>ბენზინი</li>
                            <li onClick={() => {
                                setFormData((prev) => ({...prev, fuel: "HYBRID"}));
                                setShowSections((prev) => ({...prev, fuel: false}))
                            }}>ჰიბრიდი</li>
                            <li onClick={() => {
                                setFormData((prev) => ({...prev, fuel: "PLUGIN_HYBRID"}));
                                setShowSections((prev) => ({...prev, fuel: false}))
                            }}>დატენვადი ჰიბრიდი</li>
                        </ul>
                    </div>}
                </div>
                <div className="catalogue-filter-dropdown-box">
                    <div className="filter-sections-header" onClick={() => setShowSections((prev) => ({...prev, transmission: !prev.transmission}))}>
                        <h5 className="search-filter-section-title">{formData.transmission == "" ? "ტრანსმისია" : formData.transmission.replaceAll("_", " ")}</h5>
                        <KeyboardArrowDownIcon style={{color: "black"}} />
                    </div>
                    {(showSections.transmission) && <div className={`catalogue-marks-selector`}>
                        <ul className="catalogue-marks-list">
                            <li onClick={() => {
                                setFormData((prev) => ({...prev, transmission: "AUTOMATIC"}));
                                setShowSections((prev) => ({...prev, transmission: false}))
                            }}>ავტომატიკა</li>
                            <li onClick={() => {
                                setFormData((prev) => ({...prev, transmission: "MANUAL"}));
                                setShowSections((prev) => ({...prev, transmission: false}))
                            }}>მექანიკა</li>
                            <li onClick={() => {
                                setFormData((prev) => ({...prev, transmission: "OTHER"}));
                                setShowSections((prev) => ({...prev, transmission: false}))
                            }}>სხვა</li>
                        </ul>
                    </div>}
                </div>
                <div className="catalogue-filter-dropdown-box">
                    <div className="filter-sections-header" onClick={() => setShowSections((prev) => ({...prev, drive: !prev.drive}))}>
                        <h5 className="search-filter-section-title">{formData.drive == "" ? "წამყვანი" : formData.drive.replaceAll("_", " ")}</h5>
                        <KeyboardArrowDownIcon style={{color: "black"}} />
                    </div>
                    {(showSections.drive) && <div className={`catalogue-marks-selector`}>
                        <ul className="catalogue-marks-list">
                            <li onClick={() => {
                                setFormData((prev) => ({...prev, drive: "FRONT_DRIVE"}));
                                setShowSections((prev) => ({...prev, drive: false}))
                            }}>წინა</li>
                            <li onClick={() => {
                                setFormData((prev) => ({...prev, drive: "REAR_DRIVE"}));
                                setShowSections((prev) => ({...prev, drive: false}))
                            }}>უკანა</li>
                            <li onClick={() => {
                                setFormData((prev) => ({...prev, drive: "FOUR_X_FOUR_DRIVE"}));
                                setShowSections((prev) => ({...prev, drive: false}))
                            }}>4X4</li>
                            <li onClick={() => {
                                setFormData((prev) => ({...prev, drive: "ALL_DRIVE"}));
                                setShowSections((prev) => ({...prev, drive: false}))
                            }}>All Drive</li>
                            <li onClick={() => {
                                setFormData((prev) => ({...prev, drive: "OTHER"}));
                                setShowSections((prev) => ({...prev, drive: false}))
                            }}>სხვა</li>
                        </ul>
                    </div>}
                </div>
                <div className="filter-submit-button-box">
                    <Button onClick={submitSearch} variant="contained"><SearchIcon/> ძებნა</Button>
                </div>
            </div>
        </div>
    )
}

function getYearsArray() {
    const currentYear = new Date().getFullYear();
    const startYear = 1970;
    const years = [];

    for (let year = startYear; year <= currentYear; year++) {
        years.push(year);
    }

    return years;
}

export default AsideSearchModal
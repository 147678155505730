import { Link } from "react-router-dom";
import './Car.css';
import { useState } from "react";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const Car = ({ data, idSetter, isSlider }) => {
    const [imageIndex, setImageIndex] = useState(0);

    return (
        <div className={isSlider ? "slider-items-car-container" : ""} style={{width: "100%"}}>
            <div className='car-item-container' onClick={() => {
                if(typeof idSetter !== "undefined") idSetter();
            }}>
                <div className='car-item-image' style={{ backgroundImage: `url('${data.attributes.images[imageIndex]}')` }}>
                    <button className="vehicle-card-slider-button" onClick={() => {
                        setImageIndex((prev) => {
                            return prev == 0 ? data.attributes.images.length - 1 : prev - 1;
                        })
                    }}><KeyboardArrowLeftIcon style={{color: "white", fontSize: "35px"}}/></button>
                    <button className="vehicle-card-slider-button" onClick={() => {
                        setImageIndex((prev) => {
                            return prev == data.attributes.images.length - 1 ? 0 : prev + 1;
                        })
                    }}><KeyboardArrowRightIcon style={{color: "white", fontSize: "35px"}}/></button>
                </div>
                <Link to={`/lot/${data.id}`}>
                    <div className='car-details-container'>
                        <div className="vehicle-card-details">
                            <p className='car-details-primary-damage' style={{marginBottom: "15px"}}><span style={{ color: "black" }}>მდებარეობა: </span> {data.attributes.country.replaceAll("_", " ")} {data.attributes.city.replaceAll("_", " ")}</p>
                            <h5 className='car-details-name'>{`${data.attributes.name}`}</h5>
                            <p className='car-details-primary-damage'><strong style={{ color: "black" }}>დაზიანების ტიპი: </strong> {data.attributes.damage_level.replaceAll("_", " ")}</p>
                            <p className='car-details-primary-damage'><strong style={{ color: "black" }}>გარბენი: </strong> {data.attributes.odometer_km} km</p>
                            <p className='car-details-primary-damage'><strong style={{ color: "black" }}>წამყვანი თვლები: </strong> {data.attributes.drive_type.replaceAll("_", "")}</p>
                        </div>
                        <div className='car-details-city-and-bid'>
                            <div>
                                
                            </div>
                            <div className='car-details-bid-section'>
                                <p className='car-details-high-bid'>{data.attributes.highest_bid}</p>
                                <p className="car-details-currency">USD</p>
                                <p className='car-details-buy-now-amount' style={{visibility: data.attributes.buy_now_price == "0.00" ? "hidden" : "visible"}}><strong style={{ color: 'black' }}></strong> ${data.attributes.buy_now_price}</p>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Car